.slideshow-container {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
}
.slideshow-inner {
    position:relative;
    width: 100%;
    height: 100%;
}
.mySlides {
    position:absolute;
    top:0;
    width:100%;
    height: 100%;
    opacity:0;
    transition: opacity 2s ease-in;
}
.mySlides img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }