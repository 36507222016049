/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/
html {
	box-sizing: border-box;
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;

}

*,
*::before,
*::after {
	box-sizing: inherit;
}

@-ms-viewport {
	width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}


ul.list>li {
	list-style: none;
	margin: 10px 0;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,400,300,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,500");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

body {
	background: #f8f8f8;
	font-family: "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	font-size: 0.875rem;
	line-height: 1.7;
	color: #808080;
	text-rendering: optimizeLegibility;
}



/*@media (max-width: 1199px) {.sticky-wrapper {display: none;}}*/

/*.sticky-wrapper {height: 65px;}*/

.navbar {

	padding: 0 !important;

}



/*.mm-page {box-sizing: border-box;position: relative;}*/

html.mm-background .mm-page {

	background: inherit;

}



.mm-slideout {

	-webkit-transition: -webkit-transform 0.4s ease;

	-ms-transition: -ms-transform 0.4s ease;

	transition: transform 0.4s ease;

}



html.mm-opening .mm-slideout {

	-webkit-transform: translate(80%, 0);

	-moz-transform: translate(80%, 0);

	-ms-transform: translate(80%, 0);

	-o-transform: translate(80%, 0);

	transform: translate(80%, 0);

}



@media all and (max-width: 175px) {

	html.mm-opening .mm-slideout {

		-webkit-transform: translate(140px, 0);

		-moz-transform: translate(140px, 0);

		-ms-transform: translate(140px, 0);

		-o-transform: translate(140px, 0);

		transform: translate(140px, 0);

	}

}



@media all and (min-width: 550px) {

	html.mm-opening .mm-slideout {

		-webkit-transform: translate(440px, 0);

		-moz-transform: translate(440px, 0);

		-ms-transform: translate(440px, 0);

		-o-transform: translate(440px, 0);

		transform: translate(440px, 0);

	}

}



.mainmenu {
	width: 100%;
	min-height: 70px;
	background-color: #fff;
	border-top: 3px solid #01d099;
	z-index: 9999;
}



.is-sticky .mainmenu {

	min-height: 30px;

	left: 0;

}



.sf-menu li:hover>.sf-mega,

.sf-menu li.sfHover>.sf-mega,

.mainmenu,

.sf-menu .sub-current,

.header-2 {

	border-bottom: 3px solid #01d099;

}



.sf-menu .current {

	position: relative;

}



.sf-menu ul {

	list-style: none;

	padding: 0;

}



.sf-menu .sub-current {

	position: absolute;

	display: none;

	top: 61px;

	left: 0;

	width: 160px;

	z-index: 99;

	padding-top: 3px;

}



.sf-menu .sub-current ul {

	width: 160px;

}



.sf-menu .sub-current li {

	border-bottom: 1px solid #eee;

	background-color: #fff;

}



.sf-menu .sub-current li a {

	display: block;

	margin: 0;

	position: relative;

	text-decoration: none;

	font-size: 12px;

	line-height: 16px;

	color: #131313;

	overflow: hidden;

	padding: 12px 15px;

	background: #f9f9f9;

	-webkit-transition: 0.3s all ease;

	-moz-transition: 0.3s all ease;

	-o-transition: 0.3s all ease;

	-ms-transition: 0.3s all ease;

	transition: 0.3s all ease;

}



.sf-menu li {

	cursor: pointer;

}



.sf-menu li:hover>.sub-current,

.sf-menu li.sfHover>.sub-current {

	display: block;

}



.sf-menu .sub-current .sub-current {

	top: 0;

	left: 100%;

}



.sf-menu {

	position: relative;

	margin: 0;

	padding: 0;

	list-style: none;

	z-index: 999;

}



.sf-menu .sf-mega {

	position: absolute;

	display: none;

	top: 100%;

	left: 0;

	z-index: 999;

	top: 64px;

}



.sf-menu>li {

	float: left;

}



.sf-menu li:hover>.sf-mega,

.sf-menu li.sfHover>.sf-mega {

	display: block;

	z-index: 999;

	top: 64px;

}



.sf-menu>li>a {

	display: block;

	position: relative;

}



.sf-menu .sf-mega {

	box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);

	width: 100%;

}



.sf-menu>li>a {

	text-decoration: none;

	display: block;

	font-size: 14px;

	font-weight: normal;

	color: #232323;

}



.sf-menu>li {

	margin: 0 15px 0 0;

	padding: 20px 10px 20px 10px;

	-webkit-transition: 0.2s all ease;

	-moz-transition: 0.2s all ease;

	-o-transition: 0.2s all ease;

	-ms-transition: 0.2s all ease;

	transition: 0.2s all ease;

}



.sf-menu .sf-mega ul li {

	border-bottom: 1px solid rgba(0, 0, 0, 0.08);

	line-height: 40px;

	z-index: 9999;

}



.sf-menu .sf-mega ul li:last-child {

	border-bottom: 0;

}



.sf-menu .sf-mega ul li a:hover {

	text-decoration: none;

}



.sf-menu .sf-mega li a {

	font-size: 13px;

	color: #666;

	display: block;

}



.sf-with-ul:after {

	right: 5px;

	margin-top: -1px;

}



ul.sub-current .current .sf-with-ul:after {

	content: '';

	position: absolute;

	top: 50%;

	right: 0;

	margin-right: 5px;

	height: 0;

	width: 0;

	border: 5px solid transparent;

	border-left-color: #dFeEFF;

	border-left-color: rgba(0, 0, 0, 0.5);

}



.pagination>.active>a,

.pagination>.active:hover>a,

.portfolioFilter .current:before,

.portfolioFilter a:hover:before {

	border-color: #01d099 !important;

}



ul.sub-current .current>.sf-with-ul:focus:after,

ul.sub-current .current:hover>.sf-with-ul:after,

ul.sub-current .current .sfHover>.sf-with-ul:after {

	border-left-color: #01d099;

}



blockquote>a,

.text-resalt,

.post-item h4 a:hover,

.post-item p a,

.links li i,

.entry-title a,

.sf-menu .sf-mega ul li a:hover,

.sf-menu li li a:hover,

.crumbs ul li a:hover,

.crumbs li a,

.page-error h1 i,

.sitemap li a:hover,

.pagination>li>a,

.pagination>li>span,

address i,

.events-carousel h4 a,

address a,

.list li i,

.testimonials li a:hover,

.item-feature a:hover,

.list-style li a i,

.list-style li a:hover,

.item-feature i,

.item-boxed-img i,

.next-match i,

.post-item .data-info a,

.subtitle span,

.item-boxed-service a,

.acc-trigger a:hover,

.acc-trigger.active a,

.acc-trigger.active a:hover,

.sf-menu>li:hover>a,

footer.footer-2 .links-footer ul li a:hover,

.popover-header,

.item-player .info-player h4 span,

.portfolioFilter h5 i,

.section-title-team h6 strong,

.section-title-team h6 a,

.recent-results .info-results ul li .head .date {

	color: #01d099;

}



.header-2 .sf-menu>li>a {

	font-size: 14px;

}



.header-3 .sf-menu {

	text-align: center;

	margin: 0;

}



.header-3 .sf-menu li ul {

	text-align: left;

}



.header-3 .sf-menu>li {

	margin: 0;

	float: none;

	display: inline-block;

}



.header-4 .mobile-nav {

	color: #fff;

}





.form-theme {

	margin: 0;

	width: 100%;

}



.form-theme input,

.form-theme select {

	margin-bottom: 25px;

	height: 45px;

	border-radius: 0;

	width: 100%;

}



.form-theme label {

	font-weight: normal;

}



.form-theme textarea {

	border-radius: 0;

	width: 100%;

	height: 100px;

}



.form-theme .btn {

	margin-top: 25px;

	margin-bottom: -5px;

	width: auto;

	height: auto;

	border: 0;

	font-size: 1rem;

}



.titles {

	border-bottom: #ddd 1px solid;

	margin-bottom: 30px;

}



.no-margin {

	margin: 0 !important;

}





.panel-box {

	background-color: #fff;

	margin: 0 0 40px 0;

	padding: 0;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

	border: 1px solid #eee;

	padding: 0 0 20px 0;

}



.panel-box h4 {

	font-size: 1.1rem;

}



.panel-box-grey {

	background-color: #f8f8f8 !important;

}



.titles h4 {

	color: #222;

	margin: 0;

	padding: 10px;

	border-left: 5px solid #01d099;

	white-space: nowrap;

	text-overflow: ellipsis;

	overflow: hidden;

}



.panel-box .info-panel {

	padding: 20px 25px;

}



.panel-body {

	width: 100%;

	padding-right: .5rem;

	padding-left: .5rem;

	margin-right: auto;

	margin-left: auto;

}



.panel-body>div {

	margin: 10px 0;

}





h1 a,

h2 a,

h3 a,

h4 a,

h5 a,

h6 a,

.h1 a,

.h2 a,

.h3 a,

.h4 a,

.h5 a,

.h6 a {

	color: #151e25;

}



.btn-primary,

.btn-primary:hover,

.nav-tabs li.active a,

.nav-tabs li a.active,

.nav-tabs li a.active:hover,

.nav-tabs li a:hover,

.title-color,

.title-aside,

.pagination .active,

.hero-video .info-hero h1:before,

.item-boxed-img.small h4:before,

.item-player .btn,

.btn-iw.outline:hover,

.info-slider h1:before,

.btn.theme,

.section-title.single-result .result-match,

.clear-title:after {

	background: #01d099 !important;

	background-color: #01d099 !important;

	border-color: #00bb89;

	color: #383838;

}



input,

button,

select,

optgroup,

textarea {

	margin: 0;

	font-family: inherit;

	font-size: inherit;

	line-height: inherit;

}



button,

select {

	text-transform: none;

}



select.form-control:not([size]):not([multiple]) {

	height: calc(2.25rem + 2px);

}



select.form-control:focus::-ms-value {

	color: #495057;

	background-color: #fff;

}



select.form-control-sm:not([size]):not([multiple]),

.input-group-sm>select.form-control:not([size]):not([multiple]),

.input-group-sm>select.input-group-addon:not([size]):not([multiple]),

.input-group-sm>.input-group-btn>select.btn:not([size]):not([multiple]) {

	height: calc(1.8125rem + 2px);

}



select.form-control-lg:not([size]):not([multiple]),

.input-group-lg>select.form-control:not([size]):not([multiple]),

.input-group-lg>select.input-group-addon:not([size]):not([multiple]),

.input-group-lg>.input-group-btn>select.btn:not([size]):not([multiple]) {

	height: calc(2.3125rem + 2px);

}



.form-control {

	display: block;

	width: 100%;

	padding: 0.5rem 0.75rem;

	font-size: 1rem;

	line-height: 1.25;

	color: #495057;

	background-color: #fff;

	background-image: none;

	background-clip: padding-box;

	border: 1px solid rgba(0, 0, 0, 0.15);

	border-radius: 0.25rem;

	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

}



.form-control::-ms-expand {

	background-color: transparent;

	border: 0;

}



.form-control:focus {

	color: #495057;

	background-color: #fff;

	border-color: #80bdff;

	outline: none;

}



.form-control::placeholder {

	color: #868e96;

	opacity: 1;

}



.form-control:disabled,

.form-control[readonly] {

	background-color: #e9ecef;

	opacity: 1;

}



.form-control-file,

.form-control-range {

	display: block;

}



.form-control {

	font-size: 0.8rem;

}



.form-control+.input-group-addon:not(:first-child) {

	border-left: 0;

}



.alert {

	max-width: 100%;

	z-index: 4096;

	margin: 5%;

}

/*
.loading { display: flex; justify-content: center; align-items: center; height: 100vh; font-size: 1.5rem;}
.loading { position: fixed; top: 0; left: 0; width: 100vw; background-color: rgba(0, 0, 0, 0.300); z-index: 1100;}
.loading { background: rgb(3 2 1 / 43%); display: block; width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 999999;}
.loading-container { position: absolute; top: 0; left: 0; z-index: 2048; width: 100vw; display: none; align-content: center;}
*/


.owl-carousel .item img {

	display: block;

	widows: auto;

	height: 100%;

	max-height: 500px;

	width: auto;

}



.owl-item .item img {

	margin: auto;

	position: relative;

}







.section-title {
	height: 180px;
	background: url(https://10liga.com/site/assets/images/top-section.jpg);
	padding: 1%;
	background-size: 100%;
}



.section-title>h1 {

	font-weight: bold;

}







.in {

	opacity: 1 !important;

}



div.home {
	background-image: url(custom/images/000000-0.7.png);
	position: fixed;
	top: 70px;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
}

div.home>h1,
div.home>h2 {
	margin: 5% auto 0 auto;
	text-align: center;
	color: #ffffff;
	font-size: 400%;
	font-weight: bold;
	text-shadow: 0px 0px 10px #000;
}

div.home>h2 {
	font-size: 300% !important;
	text-shadow: 0px 0px 5px #000 !important;
}


div.home>div.botonesHome {
	margin-top: 5%;
	text-align: center;
}

div.home>div.botonesHome>button {
	margin: 20pt;
}

table {
	width: 100%;
	background: #fff;
	border: 1px solid #dedede;
	border-collapse: collapse;
}

table thead tr th {
	padding: 20px 20px;
	border: 1px solid #dedede;
	color: #000;
}
/*
table.table-striped tbody tr:nth-of-type(odd) {
	background: #f9f9fa;
}
*/


table tr td {

	padding: 10px;

	border: 1px solid #dedede;

}



table tr td img {

	max-width: 40px;

	max-height: 25px;

	margin: 0 2px;

}



.material-icons-outlined {

	cursor: pointer;

}



.owl-carousel {

	min-height: 400px;

	margin: auto;

}



input.error,

select.error {

	border: 1px solid #f00;

}



input.valid,

select.valid {

	border: 1px solid #0f0;

}



label.error {

	color: red;

	font-weight: bold;

	margin-top: -20px;

	margin-top: -25px;

	position: absolute;

}



@media (max-width: 992px) {

	.no-sm {

		display: none;

	}

}



@media (max-width: 768px) {

	* {

		font-size: small;

	}



	table tbody tr td,

	table thead tr th {

		padding: 3px;

	}

}





.item {

	background-color: #fff;

	position: relative;

	overflow: hidden;

}



.item .item-head {

	overflow: hidden;

	height: 165px;

	position: relative;

}



.item .item-head img {

	max-width: 100%;

	max-height: 100%;

}



.item .item-info .item-logo {

	width: 70px;

	height: 70px;

	display: flex;

	align-items: center;

	justify-content: center;

	background: rgba(0, 0, 0, 0.8);

	border-radius: 50%;

	position: absolute;

	top: -35px;

	left: 50%;

	margin-left: -35px;

}



.item .item-info {

	padding: 50px 0 0 0;

	text-align: center;

	position: relative;

}



.item .item-info .item-logo img {

	max-width: 96%;

	max-height: 96%;

	margin: 2%;

}



.item .item-footer {

	padding: 10px;

}



.item .item-footer {

	padding: 0 20px;

}



.torneo-nombre {

	color: #fff;

	font-weight: 600;

}



div.equipo-imagen-perfil>img {
	max-width: 100%;
}



aside.datos-jugador,

aside.datos-equipo {

	display: none;

}




/*
.registro, .buscador, .torneos, .tabla, .fixture, .equipos, .goleadores, .sedes, .equipo, .login, .sede, .jugadores, .jugador, .fecha, .partido, .como, .datos, .datos-jugador, .datos-equipo, .share { display: none;}
*/


/* ICONOS*/

.ico {

	background-size: contain;

	background-repeat: no-repeat;

	padding: 0;

}



.ico-pelota {

	background-image: url(https://10liga.com/site/assets/images/svg/pelota.svg);

}



.ico-numero {

	background-image: url(https://10liga.com/site/assets/images/svg/numero.svg);

}



.ico-puesto {

	background-image: url(https://10liga.com/site/assets/images/svg/puesto.svg);

}



.ico-peso {

	background-image: url(https://10liga.com/site/assets/images/svg/peso.svg);

}



.ico-altura {

	background-image: url(https://10liga.com/site/assets/images/svg/altura.svg);

}



.ico-TA {

	background-image: url(https://10liga.com/site/assets/images/svg/tarjetaAmarilla.svg);

}



.ico-TR {

	background-image: url(https://10liga.com/site/assets/images/svg/tarjetaRoja.svg);

}



.ico-escudo {

	background-size: contain;

	background-repeat: no-repeat;

}



.home h1 {

	font-size: 5rem;

	font-weight: bold;

	color: #eee;

}



div.datos-jugador div.panel-body div.row-dashed,

div.datos-equipo div.panel-body div.row-dashed {

	border-bottom: 1px dashed #ddd;

}



.img-50 {
	opacity: .5;
}



.container-partido {
	background-image: url(https://10liga.com/site/assets/images/backMatch.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	display: inline-block;
	min-height: 400px;
	max-height: 400px;
	width: 100%;
	position: relative;
}



.partido-equipo {
	width: 100%;
	height: 200px;
	float: left;
	position: absolute;
	top: 0;
	left: 0;
}



.partido-equipo-local {
	border-bottom: 2px solid #fff;
}


.partido-equipo .dato {
	width: 20px;
	height: 40px;
	background-size: contain;
	background-repeat: no-repeat;
	padding: 0;
	position: absolute;
	color: #fff;
	text-align: center;
}



.partido-equipo-local .dato {

	top: 100%;

	margin-top: -40px;

	background-position: center bottom;

}




























td div.gol {
	background-image: url(https://10liga.com/site/assets/images/svg/pelota.svg);
}

td div.TA {
	background-image: url(https://10liga.com/site/assets/images/svg/tarjetaAmarilla.svg);
}

td div.TR {
	background-image: url(https://10liga.com/site/assets/images/svg/tarjetaRoja.svg);
}

td div.dato {
	width: 40px;
	height: 20px;
	background-size: contain;
	background-repeat: no-repeat;
}

td.visitante div.dato {
	float: left;
}

td.local div.dato {
	background-position: right;
}

.partido-equipo-visitante {
	top: 200px;
}


.partido-equipo-visitante .dato {

	background-position: center top;

	padding-top: 20px;

}



.partido-equipo-visitante h5 {

	margin-bottom: 0;

	top: 100%;

	position: inherit;

	margin-top: -40px;

	float: right;


}



.partido-primer-tiempo {

	height: 400px;

	position: relative;

	width: 50%;

	float: left;

	display: inline;
	border-right: 1px solid #fff;
}



.partido-equipo h5 {

	color: #fff;

}



.partido-equipo .gol {
	background-image: url(https://10liga.com/site/assets/images/svg/pelota.svg);
}



.partido-equipo .TA {
	background-image: url(https://10liga.com/site/assets/images/svg/tarjetaAmarilla.svg);
}



.partido-equipo .TR {

	background-image: url(https://10liga.com/site/assets/images/svg/tarjetaRoja.svg);

}



.get-link {

	cursor: pointer;

}



.img-share {

	max-height: 32px;

	max-width: 32px;

}



.share div a {

	margin: .4rem;

}



.accordion-button:not(.collapsed) {

	color: #383838;

	background-color: #01d099;

}



div.datos-jugador-detalle ul {

	list-style: none;

	padding: 0;

}



div.datos-jugador-detalle ul li {

	margin-top: 3%;

	margin-right: 2%;

	margin-left: 2%;

	border-bottom: 1px dashed #ddd;

}



div.datos-jugador-detalle ul li:last-child {

	border-bottom: 1px solid #aaa;

}





div.datos-jugador-detalle ul li h3 {

	text-align: center;

}



div.datos-jugador-detalle ul li div.ico {

	height: 32px;

	position: absolute;

	width: 32px;

}


.stats-info ul {
	border-top-color: #01d099 !important;
}

.stats-info {
	margin-bottom: 50px;
}

.stats-info ul {
	border-top: 3px solid;
	background: #fff;
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: table;
}

.stats-info ul li {
	text-align: center;
	padding: 10px 20px;
	float: left;
	border: 1px solid #dedede;
	border-right: 0;
}

.stats-info ul li:last-child {
	border-right: 1px solid #dedede;
}

div.datos-jugador div div div.ico {
	width: 1.5em;
}



/** skeleton */
.header .img::before,
.details span::before,
.line::before,
.btns .btn::before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0, 0, 0, 0.05) 20%, #d9d9d9 40%, #d9d9d9 100%);
	background-repeat: no-repeat;
	background-size: 450px 400px;
	animation: shimmer 1s linear infinite;
}

div.list-campeonato div.line {
	min-height: 40px;
	max-height: 40px;
	position: relative;
}

div.torneos-list div.line {
	min-height: 257px;
	max-height: 257px;
	min-width: 277px;
	max-width: 277px;
	position: relative;
}

table.sk>tbody>tr>td {
	height: 40px;
}

table.sk>tbody>tr>td.line::before {
	position: relative !important;
	float: left;
}

td .img::before {
	position: relative;
	content: "";
	height: 20px;
	width: 50%;
	background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0, 0, 0, 0.05) 20%, #d9d9d9 40%, #d9d9d9 100%);
	background-repeat: no-repeat;
	background-size: 450px 400px;
	animation: shimmer 1s linear infinite;
	display: inline-block;
}

.header .img::before {
	background-size: 650px 600px;
}

.details span::before {
	animation-delay: 0.2s;
}

.btns .btn-2::before {
	animation-delay: 0.22s;
}

@keyframes shimmer {
	0% {
		background-position: -450px 0;
	}

	100% {
		background-position: 450px 0;
	}
}

/* fin skeleton */


@media only screen and (max-width: 575px) {
	.partido {
		display: none;
	}

	.partido-sm {
		display: block;
	}
}

@media only screen and (min-width: 575px) {
	.partido {
		display: block;
	}

	.partido-sm {
		display: none;
	}
}

.spinner {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #00000066;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
}

.half-spinner {
	width: 50px;
	height: 50px;
	border: 3px solid #03fc4e;
	border-top: 3px solid transparent;
	border-radius: 50%;
	animation: spin 0.5s linear 0s infinite;
}


.footer {
	background-color: #000;
	padding: 2% 1%;
}


div.contactanos {
	position: relative;
	text-align: center;
}

div.contactanos>img {
	position: relative;
	max-height: 60px;
	margin-top: 0px;
}

div.modal-backdrop {
	z-index: 10000 !important;
}

div.modal {
	z-index: 10010 !important;
}









.bsb-timeline-2 {
	--bsb-tl-color: #01d099;
	--bsb-tl-circle-color: #01d099;
	--bsb-tl-circle-size: 32px;
	--bsb-tl-circle-offset: 16px
}

.bsb-timeline-2 .timeline {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative
}

.bsb-timeline-2 .timeline:after {
	background-color: #01d099;
	bottom: 0;
	content: "";
	left: 0;
	margin-left: -1px;
	position: absolute;
	top: 0;
	width: 2px
}

.bsb-timeline-2 .timeline>.timeline-item {
	margin: 0;
	padding: 0;
	position: relative
}

.bsb-timeline-2 .timeline>.timeline-item .timeline-icon {
	align-items: center;
	background-color: var(--bsb-tl-circle-color);
	border-radius: 50%;
	display: flex;
	height: var(--bsb-tl-circle-size);
	justify-content: center;
	left: calc(var(--bsb-tl-circle-offset)*-1);
	position: absolute;
	top: 0;
	width: var(--bsb-tl-circle-size);
	z-index: 1
}

.bsb-timeline-2 .timeline>.timeline-item .timeline-body {
	margin: 0;
	padding: 0;
	position: relative
}

.bsb-timeline-2 .timeline>.timeline-item .timeline-content {
	padding: 0 0 2.5rem 2.5rem
}

@media(min-width:768px) {
	.bsb-timeline-2 .timeline>.timeline-item .timeline-content {
		padding-bottom: 3rem
	}
}

.bsb-timeline-2 .timeline>.timeline-item:last-child .timeline-content {
	padding-bottom: 0
}

.timeline-content .card {
	background-color: transparent;
}

.timeline-content .card-title {
	color: #555;
	font-weight: bold;
}

.timeline-content .card-subtitle {
	color: #000 !important;
	font-weight: bold;
}